import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "transfromToYearMonthLabel"
})
export class TransfromToYearMonthLabelPipe  implements PipeTransform  {

  public transform(period: any, ...args: any[]) {

    if(period < 0) return "Non Recoverable";

    if(period > 0 && period < 12) {
      return `${period} months`;
    }

    const result: any = period / 12;
    const year: any   = parseInt(result);
    const month: any  = period % 12;

    let monthLabel = '';
    if(month == 1 ) monthLabel = `${month} month`
    if(month > 1  ) monthLabel = `${month} months`

    let yearLabel = '';
    if(year == 1  ) yearLabel = `${year} year`
    if(year > 1   ) yearLabel = `${year} years`

    let delimiterLabel = 'and';
    if(year == 0 || month == 0) delimiterLabel = '';

    return `${yearLabel} ${delimiterLabel} ${monthLabel}`;
  }
}
