import { Component, inject, OnInit  } from "@angular/core";
import { ButtonModule               } from 'primeng/button';
import { TableModule                } from 'primeng/table'
import { lastValueFrom              } from "rxjs";

import { GlobalRedirectService      } from "app/@routes/redirect.service";
import { CustomerApi                } from "nv@api/customer.api.service";
import { CcTable                    } from "nv@components/cc-table/cc-table.component";
import { ApiResponseModel           } from "nv@services/http.service";
import { TranslatePipe              } from "nv@services/translate.service";

import { DashboardState             } from "./dashboard.state";
import { SidebarManageCustomers     } from "../../@modals/manage-customers/manage-customers.component";

@Component({
  selector    : 'page-main-customer',
  templateUrl : './dashboard.component.html',
  providers   : [DashboardState],
  imports     : [
    ButtonModule            ,
    TableModule             ,
    CcTable                 ,
    SidebarManageCustomers  ,
    TranslatePipe
  ]
})
export class DashboardPage implements OnInit {

  public $$ui             = inject(DashboardState)
  private globalRedirect  = inject(GlobalRedirectService);
  private $customerApi    = inject(CustomerApi)

  public customerCollection: any = [];

  public async ngOnInit() {
    await this.fetchCustomer();
  }

  /**
   * @author Mihail Petrov
   */
  public processOnCreateNewCustomer() {

    this.$$ui.state.isSidebarCreateNewCustomerVisible = true
    this.$$ui.data.sidebarTransactionAction           = 'create';
  }

  /**
   * @author Mihail Petrov
   */
  public processOnImportNewCustomer() {

    this.$$ui.state.isSidebarCreateNewCustomerVisible = true
    this.$$ui.data.sidebarTransactionAction           = 'import';
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public processOnCustomerEdit($event: any) {

    this.$$ui.state.isSidebarCreateNewCustomerVisible = true;
    this.$$ui.data.sidebarTransactionObject           = $event;
    this.$$ui.data.sidebarTransactionAction           = 'edit';
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public processOnCustomerDelete($event: any) {

    this.$$ui.state.isSidebarCreateNewCustomerVisible = true;
    this.$$ui.data.sidebarTransactionObject           = $event;
    this.$$ui.data.sidebarTransactionAction           = 'delete';
  }

  /**
   *
   * @param $event
   */
  public processOnCustomerNavigate($event: any) {
    this.globalRedirect.inContextOfCustomer().viewSpecificCustomer($event.id);
  }

  /**
   * @author Mihail Petrov
   * @param $obj
   */
  public async saveCustomer($obj: any) {

    if(this.$$ui.data.sidebarTransactionAction == "create"  ) await this.createNewCustomer($obj);
    if(this.$$ui.data.sidebarTransactionAction == "edit"    ) await this.editExistingCustomer($obj);
    if(this.$$ui.data.sidebarTransactionAction == "delete"  ) await this.removeExistingCustomer($obj);
    if(this.$$ui.data.sidebarTransactionAction == "import"  ) await this.importCustomer($obj);
  }

  /**
   * @author Mihail Petrov
   * @param $obj
   */
  public async importCustomer($obj: any) {

    const response = await lastValueFrom(this.$customerApi.importCustomer($obj));
    await this.fetchCustomer();
  }

  /**
   * @author Mihail Petrov
   * @param $pageId
   */
  public async processOnChangePage($pageId: any) {

    const response          = await lastValueFrom(this.$customerApi.getAllCustomers($pageId));
    this.customerCollection = response.data;
  }

  /**
   * @author Mihail Petrov
   * @param $searchValue
   */
  public async processOnSearchCustomer($searchValue: string) {

    const response          = await lastValueFrom(this.$customerApi.getAllCustomers({ name: $searchValue}));
    this.customerCollection = response.data;
  }


  /**
   * @author Mihail Petrov
   * @param $obj
   */
  private async createNewCustomer($obj: any) {

    try {

      const response: ApiResponseModel  = await lastValueFrom(this.$customerApi.createNewCustomer($obj));
      const pkid: any                   = response.data.id;

      this.globalRedirect.inContextOfCustomer().viewSpecificCustomer(pkid);
    }
    catch(error) {
      console.log(error)
    }
  }

  /**
   * @author Mihail Petrov
   * @param $obj
   */
  private async editExistingCustomer($obj: any) {

    await lastValueFrom(this.$customerApi.updateCustomer($obj));
    this.customerCollection = (await lastValueFrom(this.$customerApi.getAllCustomers())).data;
  }

  /**
   * @author Mihail Petrov
   */
  private async removeExistingCustomer($obj: any) {

    await lastValueFrom(this.$customerApi.removeCustomer($obj.id));
    this.customerCollection = (await lastValueFrom(this.$customerApi.getAllCustomers())).data;
  }

  /**
   *
   */
  private async fetchCustomer() {

    const response              = await lastValueFrom(this.$customerApi.getAllCustomers());
    this.customerCollection     = response.data;
    this.$$ui.state.hasCustomer = this.customerCollection.length > 0;
  }
}
