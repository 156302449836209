<form [formGroup]="$form()" style="height: 100%;">

  <!-- <div style="display: flex; flex-direction: column;justify-content: space-between; height: 100%;"> -->
  <div class="layout--col">

    <div class="mt16">

      <div
        style   ="border: solid #dedede 1px; padding: 8px; padding-left: 24px; padding-right: 24px; cursor: pointer;" class   ="step-title"
        (click) ="downloadTemplate()">
        <i class="pi pi-file-excel mr16"></i>
        {{'@@MANAGE_IMPORT.ACTION.TEMPLATE' | translate}}
      </div>


      <div class="panel mt16">{{'@@MANAGE_IMPORT.MESSAGE' | translate }}</div>

      <div class="mt32">
        <p-fileUpload
          [style]     = "{'width': '100%'}"
          mode        = "basic"
          chooseLabel = "Upload Contact template file"
          chooseIcon  = "pi pi-upload"
          maxFileSize = "1000000"
          (onSelect)  = "onUpload.emit($event)" />
      </div>
    </div>
  </div>
</form>
