import {  inject        ,
          Injectable    } from "@angular/core";
import {  RoiApi        } from "nv@api/roi.api.service";
import {  lastValueFrom ,
          Subject       } from "rxjs";
import {  ScoreModel    } from "../@models/score.model";

@Injectable()
export class SiteService {

  private $roiApiService    = inject(RoiApi);

  public sub$               = new Subject();
  public serviceProcessor$  = new Subject();

  public sendData(key: string) {
    return this.sub$.next(key);
  }

  /**
   * @author Mihail Petrov
   * @param formKey
   * @param value
   * @returns
   */
  public sendFormData(formKey: string, value: any) {
    return this.serviceProcessor$.next({formKey, value});
  }

  /**
   * @author Mihail Petrov
   * @param entity
   */
  public async $serviceGetUtilityCollection(entity: any) {

    const utilityCollection: any  = await lastValueFrom(this.$roiApiService.getUtilityData({
      ...entity.latLong
    }));

    this.sendFormData('process@UTILITY', utilityCollection);
  }

  /**
   * @author Mihail Petrov
   * @param entity
   */
  public async $serviceGetUtilityPlan(entity: any) {

    const ratePlanCollection  = await lastValueFrom(this.$roiApiService.getRatePlans({
      ...entity.latLong,
      "utility" : entity.utility
    }));

    this.sendFormData('ratePlanCollection', ratePlanCollection);
  }

  /**
   * @author Mihail Petrov
   * @param entity
   */
  public async $serviceGetGeocodeData(entity: any) {

    const latLongOfThisSite: any  = await lastValueFrom(this.$roiApiService.getGeocodeData(entity));
    this.sendFormData('process@GEOCODE', latLongOfThisSite);
  }

  /**
   * @author Mihail Petrov
   */
  // public $scoreSite(siteCollection: ScoreModel) {
    public $scoreSite(siteCollection: any) {


    return this.$roiApiService.getReturnOfInvestment({

      "planName"                : siteCollection.utilityPlan,
      "planSector"              : siteCollection.utilityPlanGroup,
      "planId"                  : siteCollection.utilityPlanId,

      "projectStartDate"        : new Date(siteCollection.anticipatedStartDate)?.toISOString()?.slice(0, 10),

      "latitude"                : siteCollection.latLong.latitude,
      "longitude"               : siteCollection.latLong.longitude,
      "state"                   : siteCollection.state,

      "operatingCost"           : siteCollection.operatingCost as number,
      "incentives"              : siteCollection.incentives as number,
      "has30c"                  : siteCollection.hasIncentives30c,
      "hasBABA"                 : siteCollection.hasIncentivesBABA,

      "subscriptionFeePerMonth" : siteCollection.monthlySubscription,
      "monthlySoftwareCost"     : 315,

      "hardwareConfig"          : this.transformHardwareConfigToCabinets(siteCollection.hardwareConfigCollection),
      "hardwareCost"            : siteCollection.hardwareCost,
      "installCost"             : siteCollection.installationCost,
      "totalSockets"            : siteCollection.numberOfPorts,

      "flatFeePerSession"       : 0,

      "utilityCost"             : siteCollection.utilityCost,

      "driverPriceMultiplier"   : siteCollection.driverPriceMultiplier,
      "zip"                     : siteCollection.zip,
      "kwhPerVisit"             : 25,

      "utilization"             : siteCollection.utilization
    })
  }


    private transformHardwareConfigToCabinets(hardwareConfigCollection: any) {

        // Initialize the cabinets array
        const cabinets: any = [];

        hardwareConfigCollection.forEach((config: any) => {
          console.log(config)
            const { numberOfUnits, numberOfPorts, hardwareCapacity } = config;

            // Create chargers array based on numberOfUnits
            const chargers = [];
            for (let i = 0; i < numberOfUnits; i++) {
                chargers.push({
                    capacity  : hardwareCapacity || 0,
                    ports     : numberOfPorts,
                });
            }

            // Add a cabinet with the chargers
            cabinets.push({
                capacity: 700,
                chargers: chargers,
            });
        });

        return cabinets;
    }


}
