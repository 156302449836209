<div class="flex" style="width: 100%;">

  <!-- ROI Card -->
  <div  class="box" style="display: flex; flex-direction: column;">

    <div class="box" style="height: 100%; border: none; border-bottom: solid #dedede 1px;">
      <div class="icon">
        <i class="pi pi-circle-fill"></i>
        <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.NUMBER_OF_SITES' | translate}}</div>
      </div>

      <div class="value">{{inputData.numberOfSites }}</div>
    </div>


    <div class="box" style="height: 100%; border: none;">
      <div class="icon">
        <i class="pi pi-circle"></i>
        <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.NUMBER_OF_SCORED_SITES' | translate}}</div>
      </div>

      <div class="value">{{ inputData.numberOfScoredSites }}</div>
    </div>
  </div>

  <!-- ROI Card -->
  <div  class="box" style="display: flex; flex-direction: column;">

    <div class="box" style="height: 100%; border: none; border-bottom: solid #dedede 1px;">
      <div class="icon">
        <i class="pi pi-calendar"></i>
        <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.FIRST_DEPLOYMENT' | translate}}</div>
      </div>

      <div class="value">{{inputData.deploymentDay| date:'mediumDate'}}</div>
    </div>


    <div class="box" style="height: 100%; border: none;">
      <div class="icon">
        <i class="pi pi-chart-line"></i>
        <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.EXPECTED_ROI' | translate}}</div>
      </div>

      <div class="value">{{ inputData.expectedRoi | transfromToYearMonthLabel }}</div>
    </div>
  </div>


  <!-- ROI Card -->
  <div  class="box" style="display: flex; flex-direction: column;">

    <div class="box" style="height: 100%;  border: none; border-bottom: solid #dedede 1px;">
      <div class="icon">
        <i class="pi pi-car"></i>
        <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.PORTS' | translate}}</div>
      </div>

      <div class="value">{{inputData.numberOfPorts}}</div>
    </div>

    <div class="box" style="height: 100%; border: none;">
      <div class="icon">
        <i class="pi pi-bolt"></i>
        <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.ENERGY' | translate}}</div>
      </div>

      <div class="value">{{inputData.expectedEnergyConsumption | number: '1.0-0'}} kWh</div>
    </div>
  </div>


  <!-- Operating expence Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-dollar"></i>
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.OPERATING_EXPENSE' | translate}}</div>
    </div>

    <!--  -->
    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.FIXED_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData.expectedFixedCost | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.DEMAND_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData.expectedDemandCost | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.ENERGY_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData.expectedEnergyCost | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.OTHER_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData.expectedOperationCost | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="value">{{(inputData.totalOperatingExpenses)| currency:'USD':'symbol':'1.0-0'}}</div>
  </div>

  <!-- Capital expence Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-dollar"></i>
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.TOTAL_EXPENCE' | translate}}</div>
    </div>

    @if(inputData?.expectedIncentives && inputData.expectedIncentives > 0)
    {
      <div class="flex mt8">
        <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.INCENTIVES' | translate}}</div>
        <strong style="color: #6bbe6f;">{{inputData.expectedIncentives | currency:'USD':'symbol':'1.0'}}</strong>
      </div>
    }

    @if(inputData?.incentive30c && inputData?.incentive30c > 0)
    {
      <div class="flex mt8">
        <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.INCENTIVE30C' | translate}}</div>
        <strong style="color: #6bbe6f;">{{inputData?.incentive30c | currency:'USD':'symbol':'1.0'}}</strong>
      </div>
    }

    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.HARDWARE_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData.expectedHardwareCost | currency:'USD':'symbol':'1.0'}}</strong>
    </div>
    <div class="flex mt8">
      <div class="title">{{'@@PROJECT.TEMPLATE.SUMMARY.INSTALLATION_COST' | translate}}</div>
      <strong style="color: #d91c5c;">{{inputData.expectedInstallationCost | currency:'USD':'symbol':'1.0'}}</strong>
    </div>

    <div class="value">{{(inputData.totalCapitalExpenses )| currency:'USD':'symbol':'1.0'}}</div>
  </div>

</div>
