<form [formGroup]="$form" style="height: 100%;">

  <!-- <div style="display: flex; flex-direction: column;justify-content: space-between; height: 100%;"> -->
  <div class="layout--col">


    <div>
      <div class="mt16">
        <label>{{'@@MANAGE_PROJECTS.PAGE.NAME' | translate }} * </label>
        <input class              = "input"
              [class.is-invalid]  = "$form.isValid($form, 'name')"
              type                = "text"
              formControlName     = "name"
              placeholder         = "Project name"/>
              <cc-form-error [inputForm]="$form" [inputField]="'name'"></cc-form-error>
      </div>

      @if(projectContactList && projectContactList.length > 0)
      {
        <div class="mt16">
          <div>{{'@@MANAGE_PROJECTS.PAGE.CONTACT_LIST' | translate }}</div>
          @for(contact of projectContactList; track contact.id)
          {
            <div
              style   = "border-bottom: solid #dedede 1px;"
              class   = "contact-element mt8 pb8 pl8 pt8 flex"
              (click) = "onAddContact.emit(contact)">
              <div>
                <div style="font-weight: bold;">{{contact.firstName}} {{contact.lastName}}</div>
                <div>{{contact.role}}</div>
              </div>
              <div>

                @if(contact.isSelected)
                {
                  <div [ngClass]  = "{'is-primary': contact.isPrimary == true}"
                       style      = "font-size: 12px;"
                       (click)    = "onMakeContactPrimary.emit(contact)"
                       class      = "btn btn-plane mr8">
                    Primary contact
                  </div>

                  <div style="font-size: 12px;" (click)="onRemoveContact.emit(contact)" class="btn btn-danger mr8">
                    {{'@@MANAGE_PROJECTS.ACTION.REMOVE' | translate }}
                  </div>
                }
                @else
                {

                  <div style="font-size: 12px;" (click)="onAddContactToProject.emit(contact)" class="btn btn-plane mr8">
                    {{'@@MANAGE_PROJECTS.ACTION.ADD_TO_PROJECT' | translate }}
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }
      @else
      {
        <div class="panel mt16">{{'@@MANAGE_PROJECTS.PAGE.NO_CONTACT_FOR_PROJECT' | translate }}</div>
      }
    </div>

    <div class="mt16">
        <div (click)="onProcess.emit()" class="btn--wide btn-primary">
          <i class="pi pi-plus mr16"></i> {{'@@MANAGE_PROJECTS.ACTION.CREATE' | translate }}
        </div>
    </div>
  </div>

</form>
