<cc-sidebar
  [(inputIsVisible)]  = "inputIsVisible"
  (onHide)            = "processHideSidebar()"
  [inputTitle]        = "sidebarTitle"
  actionEnter (onEnter)="processForm()">

  @if(isInCreateEditMode())
  {
    <form [formGroup]="$form" style="height: 100%;">

      <div class="layout--col">
      <!-- <div style="display: flex; flex-direction: column;justify-content: space-between; height: 100%;"> -->

        <div class="mt16">
          <label>{{'@@MANAGE_CUSTOMERS.PAGE.CUSTOMER_NAME' | translate }} * </label>
          <input class              = "input"
                [class.is-invalid]  = "$form.isValid($form, 'name')"
                type                = "text"
                formControlName     = "name"
                placeholder         = "{{'@@MANAGE_CUSTOMERS.PAGE.CUSTOMER_NAME_PLACEHOLDER' | translate }}"/>
                <cc-form-error [inputForm]="$form" [inputField]="'name'"></cc-form-error>
        </div>

        <div (click)     = "processForm()"
             class       = "btn--wide btn-primary">
          <i class="pi pi-plus mr16"></i> {{'@@MANAGE_CUSTOMERS.ACTION.CREATE' | translate }}
        </div>
      </div>
    </form>
  }

  @if(isInDeleteMode())
  {
    <div class="panel">
      Are you sure you want to delete <span style="font-weight: bold;">{{ inputData.name }}</span>? <br>This action cannot be undone. All data associated with this customer will be permanently removed</div>

    <div class="just-flex mt16" style="justify-content: flex-end;">
      <div (click)="processHideSidebar()" class="btn mr16">
        <i class="pi pi-times"></i>
        {{'@@MANAGE_CUSTOMERS.ACTION.CANCEL' | translate }}
      </div>
      <div  (click)="removeEntity()" class="btn btn-danger">
        <i class="pi pi-trash"></i>
        {{'@@MANAGE_CUSTOMERS.ACTION.REMOVE' | translate }}
      </div>
    </div>
  }

  @if(isInImportMode())
  {
    <template-import
      [$form]     = "$form"
      (onUpload)  = "processFile($event)">
    </template-import>
  }

</cc-sidebar>
