import { APP_INITIALIZER                ,
         ApplicationConfig              } from '@angular/core';
import { provideRouter                  } from '@angular/router';

import {  routes                        } from './@routes/app.routes';
import {  HTTP_INTERCEPTORS             ,
          provideHttpClient             ,
          withFetch                     ,
          withInterceptorsFromDi        } from '@angular/common/http';
import {  provideAnimations             } from '@angular/platform-browser/animations'

import {  IPublicClientApplication      ,
          PublicClientApplication       ,
          InteractionType               ,
          BrowserCacheLocation          ,
          LogLevel                      } from '@azure/msal-browser';

import {  MsalInterceptor               ,
          MSAL_INSTANCE                 ,
          MSAL_GUARD_CONFIG             ,
          MSAL_INTERCEPTOR_CONFIG       ,
          MsalService                   ,
          MsalGuard                     ,
          MsalBroadcastService          } from '@azure/msal-angular';

import {  environment                   } from 'environments/environment';
import {  MsalGuardConfiguration        ,
          MsalInterceptorConfiguration  } from '@azure/msal-angular';
import {  AuthInterceptor               ,
          ForbiddenInterceptor          } from './@routes/auth.interceptor';
import {  LoaderInterceptor             ,
          MetaHidrationInterceptor      } from './@routes/loader.interceptor';


export function initializeMsal(msalService: MsalService): () => Promise<void> {
  return () =>
    msalService.instance
      .initialize()
      .then(() => {
        console.log('MSAL initialized successfully');
      })
      .catch((error) => {
        console.error('Error initializing MSAL: ', error);
      });
}


export function MSALInstanceFactory(): IPublicClientApplication {

  return new PublicClientApplication({
    auth: {
      clientId              : environment.msalConfig.auth.clientId,
      authority             : environment.msalConfig.auth.authority,
      redirectUri           : environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri : '/',
    },
    cache: {
      cacheLocation           : BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie  : true
    },
    system: {
      allowNativeBroker : false, // Disables WAM Broker
      loggerOptions     : {
        logLevel          : LogLevel.Info,
        piiLoggingEnabled : false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {

  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType     : InteractionType.Redirect,
    protectedResourceMap,
  };
}


export function MSALGuardConfigFactory(): MsalGuardConfiguration {

  return {
    interactionType : InteractionType.Redirect,
    authRequest     : {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed',
  };
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes) ,
    provideHttpClient(withInterceptorsFromDi(), withFetch())   ,
    provideAnimations() ,

    { provide: HTTP_INTERCEPTORS        , useClass: MsalInterceptor           , multi: true    } ,
    { provide: HTTP_INTERCEPTORS        , useClass: AuthInterceptor           , multi: true    } ,
    { provide: HTTP_INTERCEPTORS        , useClass: ForbiddenInterceptor      , multi: true    } ,
    { provide: HTTP_INTERCEPTORS        , useClass: LoaderInterceptor         , multi: true    } ,
    { provide: HTTP_INTERCEPTORS        , useClass: MetaHidrationInterceptor  , multi: true    } ,

    { provide: MSAL_INSTANCE            , useFactory: MSALInstanceFactory           } ,
    { provide: MSAL_GUARD_CONFIG        , useFactory: MSALGuardConfigFactory        } ,
    { provide: MSAL_INTERCEPTOR_CONFIG  , useFactory: MSALInterceptorConfigFactory  } ,
    MsalService         ,
    MsalGuard           ,
    MsalBroadcastService,
    {
      provide     : APP_INITIALIZER,
      useFactory  : initializeMsal,
      deps        : [MsalService],
      multi       : true,
    },
  ]
};
