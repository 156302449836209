import {  Component                   ,
          inject                      ,
          OnInit                      } from '@angular/core';
import {  CommonModule                } from '@angular/common';
import {  RouterOutlet                } from '@angular/router';
import {  DialogService               ,
          DynamicDialogModule         } from 'primeng/dynamicdialog';
import {  DialogModule                } from 'primeng/dialog';

import {  AppLoaderSpinnerComponent   } from 'nv@components/app-loader-spinner/app-loader-spinner.component';
import {  TranslateService            } from 'nv@services/translate.service';
import {  GlobalRedirectService       } from './@routes/redirect.service';
import {  MsalService                 } from '@azure/msal-angular';

@Component({
  selector    : 'app-root',
  templateUrl : './app.component.html',
  styleUrls   : ['./app.component.scss'],
  providers   : [DialogService],
  imports     : [
    CommonModule              ,
    RouterOutlet              ,
    AppLoaderSpinnerComponent ,
    DialogModule              ,
    DynamicDialogModule       ,
  ],
})
export class AppComponent implements OnInit {

  private globalRedirect    = inject(GlobalRedirectService);
  private translateService  = inject(TranslateService);
  private $auth             = inject(MsalService);

  public username           = this.$auth.instance?.getActiveAccount()?.name;
  public userSignature      = "";
  public isUsernameVisible  = true;

  public ngOnInit(): void {

    this.translateService.loadTranslations('en');

    const username              = this.$auth.instance?.getActiveAccount()?.name;
    this.isUsernameVisible      = !!username;

    const usernameCollection    = username?.split(',');
    const firstSignature        = usernameCollection ? usernameCollection[1]?.trim() : '';
    const secondSignature       = usernameCollection ? usernameCollection[0]?.trim() : '';
    this.userSignature          = `${firstSignature[0]}${secondSignature[0]}`;
    this.username               = `${firstSignature} ${secondSignature}`
  }

  public goHome() {
    this.globalRedirect.inContextOfCustomer().viewAllCustomers();
  }
}
