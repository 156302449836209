<cc-sidebar
  [(inputIsVisible)]  = "inputIsVisible"
  (onHide)            = "processHideSidebar()"
  [inputTitle]        = "sidebarTitle">

  <div class="layout--col">
  <!-- <div style="display: flex; flex-direction: column;justify-content: space-between; height: 100%;"> -->
    <div>

      <div
        [ngClass]="projectStatus == 'OPEN' ? 'selected': ''"
        style="cursor: pointer; border: solid #dedede 1px; padding: 16px; border-radius: 4px;"
        (click)="projectStatus = 'OPEN'">Active / Open</div>

      <div
        [ngClass]="projectStatus == 'CLOSE_WON' ? 'selected': ''"
        style="cursor: pointer; border: solid #dedede 1px; padding: 16px; border-radius: 4px; margin-top: 16px;"
        (click)="projectStatus = 'CLOSE_WON'">Closed - Won</div>

      <div
        [ngClass]="projectStatus == 'CLOSE_LOST' ? 'selected': ''"
        style="cursor: pointer; border: solid #dedede 1px; padding: 16px; border-radius: 4px; margin-top: 16px;"
        (click)="projectStatus = 'CLOSE_LOST'">Closed - Lost</div>

      <div
        [ngClass]="projectStatus == 'CLOSE_FUNDING' ? 'selected': ''"
        style="cursor: pointer; border: solid #dedede 1px; padding: 16px; border-radius: 4px; margin-top: 16px;"
        (click)="projectStatus = 'CLOSE_FUNDING'">Closed - Funding</div>
    </div>


    <div (click)="processForm()" class="btn--wide btn-primary">
      <i class="pi pi-pencil mr16"></i> {{'@@MANAGE_CONTACTS.ACTION.EDIT' | translate }}
    </div>
  </div>


</cc-sidebar>
